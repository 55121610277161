<template>
    <div style="position:relative;"  class="heavy-civil">
        <div class="container landing-banner-container" style="padding-top: 7.5rem">
           <h1 style="font-size: 3.625rem;font-weight: bold;line-height: 1.21;color: #021222;padding-bottom: 1.875rem;"><span style="color:#00c8d8;">Heavy Civil Software</span></h1>
                  <div class="left-banner">
                <div>
                 <p>                
                    Heavy civil projects are often large and complex, involving multiple tasks, teams, and stakeholders. Since even small mistakes can have serious consequences, many civil construction require careful planning and execution. Keeping track of all aspects of the project, including scheduling, resource allocation, and progress monitoring, can be overwhelming. However, Record TIME can centralise project data, provide real-time updates, and facilitate collaboration among teams. Certainly, it can help in creating and managing schedules, tracking progress, and optimising resource allocation, leading to better project planning and execution.
                    <br><br>
                    For civil construction projects, there is minimal room for mistakes due to the project complexity, budget constraints, tight timeframes, and margins. Yet, with Record Time, you can effectively track and manage your equipment inventory, ensuring optimal utilisation and minimising downtime. Furthermore, our software allows you to schedule equipment usage, track maintenance and repairs, and monitor fuel consumption. By maximising the efficiency of your equipment, you can effectively reduce costs, improve project timelines, and enhance overall profitability.
                    <br><br><br>
                    <v-btn color="secondary" height="55" :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
                        @click="redirectToLink('https://recordtime.com.au/blog/best-construction-scheduling-software')" class="mr-7 btn-1" elevation="0">
                        Know More
                    </v-btn>
                    <v-btn height="56" class="get-demo-btn"  color="white" @click="getDemo()">
                        Get A Demo Now
                    </v-btn>
                    </p>
                </div>
            </div>
            <div  class="right-banner" style="text-align: right;">
                <img :src="`${publicPath}assets/whoweserve/heavy-civil.webp`" alt="heavy civil software for project management on construction site"  style="max-width: 90%;">
            </div>
            <div style="clear: both;"></div>
        </div>
        <v-container>
            <div class="gray-container">
                <h3>Automate Project Schedules with <span>Heavy Civil Software</span></h3>
                <img :src="`${publicPath}assets/whoweserve/construction-project-management.webp`" alt="benefits of using record time for heavy civil project management" style="margin-top: 20px; object-fit: contain; width: 100%;">
                </div>
             <br><br>
             <h2>Remain Competitive, Efficient, and Productive: Helping Hand for Contractors</h2><br/>

             <div class="left-blog1">
                <img :src="`${publicPath}assets/whoweserve/record-time-in-heavy-civil.webp`" alt="use record time for better project management in heavy civil construction"  style="width: 100%; margin-bottom: 20px;">
            </div>

            <div class="right-blog1">
               
                <p>Record TIME comes with many beneficial features to help heavy civil contractors. From plant management to <a href="https://recordtime.com.au/jobsite-management-software">job site management</a>, and digital docketing to employee time tracking, we provide all the solution you need to ensure the success of your construction projects. 
                <br><br>It's digital docketing approach revolutionises data and document management processes for heavy civil. Such as efficient storage, organisation, retrieval, and collaboration of project-related documents. Resulting in smooth workflows, reduced paperwork, better communication, and increased project success.
                <br><br>Additionally, with this heavy civil software, you can create a comprehensive <a href="https://recordtime.com.au/blog/vehicle-safety-checks-and-list-of-pre-start-checklist">list of pre start checklists</a>, track inspections, and ensure compliance with project standards. This leads to improved quality assurance and minimises rework.
               </p>
               <p><b>Contact us today to learn more about how our construction software benefits your heavy civil construction company. Let us be your trusted partner in organising your operations and driving success with this construction software.</b></p> 
            <br/>
            </div>

                      
          
            <div style="clear:both;"></div>

            
        </v-container>
        <get-demo-modal v-if="getDemoModal" :demoEmail="demoEmail" @excEmit="excEmit" @getDemoModalClose="getDemoModalClose" @demoResponse="demoResponse" url="https://www.youtube.com/embed/62kV7CIU_F4"/>

    </div>
</template>
<style lang="scss">
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
        }
    }
    .left-banner{
        width: 45%; 
        float: left;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .right-banner{
        width: 55%; 
        float: right;
        padding: 0px 20px 20px;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
            h1{ font-size: 40px !important;}
        }
    }
    .heavy-civil{
        .banner-img{
            @media only screen and (max-width: 600px) {
            position: relative !important;
            }

            @media only screen and (max-width: 1280px) {
                width: 100% !important;
                top: 20px !important;
                height: auto !important;
            }
        }
        p{
            @media only screen and (max-width: 1280px) {
                padding-bottom: 0px !important;
            }
        }
    }
    .banner-img{
        @media only screen and (max-width: 600px) {
            position: relative !important;
        }

        @media only screen and (max-width: 1280px) {
            position: relative !important;
        }
    }
    .gray-container{
        background: #F8F9FB;
        padding: 20px;
        margin-top: 30px;
        h3 {
            font-size: 32px;
            font-weight: bold;
            text-align: center;
            color: $black-pearl;
            margin-bottom: 15px;

            span {
            color: $faux-dark-turquoise;
            }
        }
        p{
            margin-top: 40px;
            text-align: center;
            color: #000;
        }
        .list{
            h1{
                color: #00C8D8;
            }
            p{
                color: #000;
                text-align: left;
            }
        }
    }
    .left-blog1{
      float:left;
      padding-right: 15px;
      width: 500px;
    }
    .right-blog1{
      max-width: calc(100% - 500px);
      float:right;
    }
    @media only screen and (max-width: 960px) {
      .left-blog1{
        float: none;
        max-width: 100%;
        width: 100%;
        text-align: center;

        padding-right: 0px;
        img{
            max-width: 500px;
        }
      }
      .right-blog1{
        float: none;
        max-width: 100%;
      }
    }

    @media only screen and (max-width: 700px) {
      .left-blog1{
        float: none;
        max-width: 100%;
        padding-right: 0px;
      }
      .right-blog1{
        float: none;
        max-width: 100%;
      }
    }
</style>
<script>
import GetDemoModal from '@/components/GetDemoModal.vue';
export default{
    metaInfo: {
    title: 'Heavy Civil Software for Project Management | Record TIME',
    // titleTemplate: 'Record TIME → %s',
    meta: [
      {name: 'name', content: ''},
      {name: 'description', content: 'Heavy civil software for civil contractors to easily manage and schedule tasks for all your construction project operations.'},
      {name: 'keyword', content: 'heavy civil software'}
      ],
    link: [
      { rel: 'canonical', href: 'https://recordtime.com.au/heavy-civil-software' }
    ]
  },
  
    components: {
        GetDemoModal
        
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            getDemoModal: false,
        }
    },
    methods: {
        redirectToLink(url) {
        window.open(url, '_blank');
        },

        getDemoModalClose(){
            this.getDemoModal = false;
            this.demoEmail = '';
        },
        getDemo(){
            this.getDemoModal = true;
        }
    }
}
</script>
